import { NgModule } from '@angular/core';
import { CommonModule , DatePipe  } from '@angular/common';

import { PayMethodRoutingModule } from './pay-method-routing.module';
import { PayMethodComponent } from './pay-method.component';
import { FormsModule }   from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {InputMaskModule} from 'primeng/inputmask';



@NgModule({
  declarations: [PayMethodComponent],
  imports: [
    CommonModule,
    PayMethodRoutingModule,
    FormsModule,
    CalendarModule,
    InputMaskModule
  ],
  providers: [
    DatePipe
  ]
})
export class PayMethodModule { }
