<div class="container">
  <form class="pay" (ngSubmit)="handlePayment(f)" #f="ngForm">
    <div class="row">
      <div class="col-lg-9 col-12">
        <div class="header-card">
          <p>طريقة الدفع</p>
        </div>
        <div class="body-card">
          <div>
            <div class="fram">
              <div class="payment flex-wrap">
                <div class="title">
                  <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="حساب بنكي"
                  >
                  <label for="male">حساب بنكي</label><br>
                  <p>تحويل الأموال بأمان باستخدام حسابك المصرفي. Visa ، Maestro ، Discover ، American Express.</p>
                  <p class="number">0000 0000 0000 0000</p>
                </div>

                <div class="icons">
                  <img src="../../../assets/profile/payment/credit-cards_amex.svg" alt="">
                  <img class="master" src="../../../assets/profile/payment/credit-cards_mastercard.svg" alt="">
                  <img src="../../../assets/profile/payment/credit-cards_visa.svg" alt="">
                </div>
              </div>
              <div class="form">
                <span class="p-input-icon-left" style="width: 100%;">
                  <i><img src="../../../assets/profile/modalformicons/card_icon.svg" alt=""></i>
                  <label for="">رقم الحساب</label>
                  <p-inputMask 
                  [(ngModel)]="paymentInformation.cart_id" 
                  class="credit"
                  name="creditNo" 
                  mask="9999-9999-9999-9999"
                  #creditNo="ngModel"
                  [required]=true
                  ></p-inputMask>
                  <!-- <input
                  type="text"
                  pInputText
                  class="credit"
                  name="creditNo"
                  [(ngModel)]="paymentInformation.cart_id"
                  #creditNo="ngModel"
                  maxlength="16"
                  required
                  > -->
              </span>
              <div class="inputs">
                <div>
                  <label for="">اسم حامل البطاقة</label>
                  <input
                  class="w-6"
                  type="text"
                  pInputText
                  name="creditUser"
                  [(ngModel)]="paymentInformation.creditUser"
                  #creditUser="ngModel"
                  required
                  />
                </div>
                <div class="calender-payment">
                  <label for="">تاريخ الانتهاء</label>
                  <!-- <input class="w-2" type="text" pInputText placeholder="شهر / سنة"/> -->
                  <p-calendar
                  [(ngModel)]="expireDate"
                  placeholder="شهر / سنة"
                  name = "expiredate"
                  #expiredate = "ngModel"
                  [required]=true
                  view="month"
                  dateFormat="mm/yy"
                  [yearNavigator]="true"
                  yearRange="2000:2040"
                  ></p-calendar>
                </div>
                <div>
                  <span class="p-input-icon-left">
                    <i><img class="editWidth" src="../../../assets/profile/modalformicons/info.svg" alt=""></i>
                    <label for="">رمز الامان</label>
                    <input
                    class="w-2"
                    type="text"
                    pInputText
                    name="cvv"
                    [(ngModel)]="paymentInformation.cvv"
                    #cvv="ngModel"
                    maxlength="3"
                    required
                    >
                </span>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-12">
        <div class="header d-lg-block d-none">
          <p>الفاتورة</p>
        </div>
        <div class="body">
          <div class="d-flex justify-content-between mb-2">
            <p class="main-title-t1">عدد اليالي</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">{{reserveData?.dayCount}}</p>
              <p class="title-t1">ليله</p>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <p class="main-title-t1">الكارافان</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">{{reserveData?.total}}</p>
              <p class="title-t1">ريال سعودي</p>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <p class="main-title-t1">المنتجات الاضافية</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">0</p>
              <p class="title-t1">ريال سعودي</p>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <p class="main-title-t1">ضريبه مضافة</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">{{reserveData?.taxValue}}</p>
              <p class="title-t1">ريال سعودي</p>
            </div>
          </div>

          <div class="d-flex justify-content-between mb-2">
            <p class="main-title-t1">الاجمالي</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">{{totalWithTax}}</p>
              <p class="title-t1">ريال سعودي</p>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <p class="main-title-t1"> التامين المسترد</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">{{reserveData?.insuranceValue}}</p>
              <p class="title-t1">ريال سعودي</p>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <p class="main-title-t1">الأجمالي بالتامين</p>
            <div class="d-flex">
              <p class="title-t1 ms-2">{{totalWithInsur}}</p>
              <p class="title-t1">ريال سعودي</p>
            </div>
          </div>

          <button type="submit" [disabled]="f.form.invalid">ادفع الان</button>
        </div>
      </div>

    </div>
  </form>
</div>
<router-outlet></router-outlet>
