import { Component, OnInit , ViewChild} from '@angular/core';
import { PagesService} from '../../pages/pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {ActivatedRoute, Params, Router } from '@angular/router';
//import { parse } from 'path';

@Component({
  selector: 'app-pay-method',
  templateUrl: './pay-method.component.html',
  styleUrls: ['./pay-method.component.scss']
})
export class PayMethodComponent implements OnInit {
  formData = new FormData();

  @ViewChild('f') createForm!: NgForm;

  // reservation id
  reserveId:any;
  //reserve data
  reserveData:any;
  totalWithTax:any;
  totalWithInsur:any;

  // payment data
  paymentInformation:any = {};

  //response data

  responseData:any;

  // expire date
  expireDate:any;

  // redirect link
  redirectLink:any;

  constructor(
    private sharedService: SharedService,
    private pagesService: PagesService,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    //catch id from URL
    this.route.params.subscribe(
      (params: Params) => {
        this.reserveId = params['id'];
        console.log("id",this.reserveId);

      }
    );

    this.pagesService.getReservationById(this.reserveId).subscribe(
      (response: any) => {
        this.reserveData = response.data;
        console.log("responseData",this.reserveData);
        this.spinner.hide();
        this.totalWithTax=parseFloat(response.data.total)+parseFloat(response.data.taxValue);
        this.totalWithInsur=parseFloat(this.totalWithTax)+parseFloat(response.data.insuranceValue);

       // console.log("fff");
       // console.log(response.data.insuranceValue);
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  handlePayment(f:any){
    let monnth = Number(this.datepipe.transform(String(this.expireDate), 'yyyy-MM')?.split("-")[1]);
    let year = Number(this.datepipe.transform(String(this.expireDate), 'yyyy-MM')?.split("-")[0]);
    let information = {
      profile_id: "string",
      tran_type: "string",
      tran_class: "string",
      cart_description: "string",
      cart_id: this.paymentInformation.cart_id,
      cart_currency: "EGP",
      cart_amount: String(this.reserveData.total),
      resevation_id: String(this.reserveId),
      pan: "string",
      expiry_month: monnth,
      expiry_year: year,
      cvv: Number(this.paymentInformation.cvv),
      customer_id: "string",
      tran_ref: "string"
    }
    console.log("information",information , this.datepipe.transform(String(this.expireDate), 'yyyy-MM'));

    this.pagesService.payment(information).subscribe(
      (response: any) => {
        this.responseData = JSON.parse(response.data);
        this.redirectLink = this.responseData.redirect_url;
        console.log("payment response data",this.responseData , this.redirectLink);
        this.sharedService.stringErrorMessage.next('تم الارسال بنجاح :)');
        this.sharedService.errorShowMessage.next(true);
        this.sharedService.hideErrorMessage();
        window.location.href = this.redirectLink ;
        // this.contctForm.reset();
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
